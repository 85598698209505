svg.radial-progress {
  height: auto;
  max-width: 240px;
  padding: 0;
  transform: rotate(-90deg);
  width: 100%;
  background-image: linear-gradient(
    -225deg,
    #ff057c 0%,
    #8d0b93 50%,
    #321575 100%
  );
  border-radius: 50%;
}
section.svg-container svg.radial-progress:nth-child(even) {
  margin: 0 15px;
}
svg.radial-progress circle {
  fill: transparent;
  stroke: #fff;
}
svg.radial-progress circle.bar-static {
  stroke: #fafafa !important;
}
svg.radial-progress circle.bar--animated {
  stroke-dasharray: 219.91148575129;
  stroke: #ffffff;
  stroke-dashoffset: 219.91148575129;
  stroke-width: 4px;
  stroke-linecap: round;
}
svg.radial-progress text {
  fill: #fafafa;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  text-anchor: middle;
  text-shadow: 0 0 5px #0000007a;
}
